<template>
  <v-container fluid class="py-0">
    <h2>Unelgee1 Monitoring</h2>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="red"
    ></v-progress-linear>

    <div v-else>
      <p v-for="(ss,ssIndex) in schools" :key="ssIndex"> {{ ss.name }}</p>

    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import moment from "moment";
export default {
  data: () => ({
    loading: false,
    scores: null,
    schools:null
  }),
  props: {},
  components: {},
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    this.loading = true;
    fb.db.collection("schools").get().then(docs=>{
      this.schools = []
      docs.forEach(doc=>{
        let ss = doc.data()
        ss.id = doc.id
        ss.ref = doc.ref
        this.schools.push(ss)
      })
    })


    // fb.db.collectionGroup("unelgee1-2024-scores").onSnapshot((docs) => {
    //   console.log(docs);
    //   this.scores = [];
    //   this.schools = []
    //   docs.forEach((doc) => {
    //     let score = doc.data();
    //     var foundSchool = this.schools.find(ss=>ss.schoolName=score.schoolName)
    //     if(!foundSchool) this.schools.push({schoolName:score.schoolName, counter:1})
    //     else{
    //       foundSchool.counter++
    //     }
    //     this.scores.push(score);
    //   });
    //   this.loading = false;
    // });
  },

  methods: {
    print(item) {
      console.log(item);
    },
  },
  filters: {
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107; /* Your desired background color */
  color: #ffffff; /* Your desired text color */
}
</style>
